<template>
  <v-container class="w-75 mt-10">
    <v-stepper v-model="e6" vertical>
      <v-stepper-step @click="e6 = 1" :complete="e6 > 1" step="1">
        Crear cuenta de administrador
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-card color="grey lighten-5" class="mb-12">
          <v-card-text class="text--primary">
            <div class="form-wrapper">
              <v-text-field
                type="text"
                v-model="formData.admin_nombre"
                label="Nombre"
                outlined
                clearable
              />

              <v-text-field
                type="text"
                v-model="formData.admin_email"
                label="Email"
                outlined
              />

              <v-text-field
                v-model="formData.admin_password"
                label="Password"
                outlined
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show1 = !show1"
                :type="show1 ? 'text' : 'password'"
              />
            </div>
          </v-card-text>
        </v-card>
        <v-btn color="primary" @click="e6 = 2"> Continue </v-btn>
        <v-btn text class="ml-2"> Cancel </v-btn>
      </v-stepper-content>

      <v-stepper-step @click="e6 = 2" :complete="e6 > 2" step="2">
        Informacion de la iglesia
      </v-stepper-step>

      <v-stepper-content step="2">
        <v-card color="grey lighten-5" class="mb-12">
          <v-card-text>
            <div class="">
              <div class="info-iglesia">
                <!-- <label for="Icon" class="d-flex justify-center poss_relative">
                  <img
                    id="iglesia_logo"
                    :src="formData.iglesia_logo"
                    alt=""
                    srcset=""
                  />

                  <p class="poss_absolute" style="bottom: -8px">
                    Adjunte Logo (transparente)
                  </p>

                  <input
                    type="file"
                    name=""
                    id="Icon"
                    style="display: none"
                    accept="image/*"
                    @change="(e) => preview(e, 'iglesia_logo')"
                  />
                </label> -->

                <div>
                  <v-row>
                    <v-col>
                      <div class="flex justify-center">
                        <label for="iglesia_logo" class="">
                          <img
                            id=""
                            :src="formData.iglesia_logo"
                            alt=""
                            srcset=""
                          />

                          <input
                            type="file"
                            name=""
                            id="iglesia_logo"
                            style="display: none"
                            accept="image/*"
                            @change="(e) => preview(e, 'iglesia_logo')"
                          />
                        </label>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <p class="mt-n10 text-center">
                        Adjunte Logo(Fondo tranparente)
                      </p>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <div class="flex justify-center">
                        <label for="SLIDE_1_ICON" class="">
                          <img
                            id=""
                            :src="formData.SLIDE_1_ICON"
                            alt=""
                            srcset=""
                          />

                          <input
                            type="file"
                            name=""
                            id="SLIDE_1_ICON"
                            style="display: none"
                            accept="image/*"
                            @change="(e) => preview(e, 'SLIDE_1_ICON')"
                          />
                        </label>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <p class="mt-n10 text-center">
                        Foto de Inicio (1920x950) <br />
                        (Recomendable No Letras)
                      </p>
                    </v-col>
                  </v-row>
                </div>

                <div class="flex-grow-1 info-content">
                  <v-checkbox
                    label="Recibe Pagos/Donaciones"
                    v-model="formData.Donaciones"
                  />
                  <v-text-field
                    v-for="(f, index) in iglesiaInfo"
                    :key="index"
                    :name="f.label"
                    :type="f.type"
                    v-model="formData[f.model]"
                    :label="f.label"
                    outlined
                    :prefix="f.prefix ? 'www.ABCChurchs.com/' : ''"
                    @blur="
                      f.function
                        ? $store
                            .dispatch('verify_IglesiaNombre', {
                              iglesia_nombre: formData.prefijo,
                            })
                            .then((res) => {
                              readyToSubmit = res;
                            })
                        : ''
                    "
                    :hint="f.prefix ? 'No utilizar espacios' : ''"
                    @input="f.prefix ? RemoveSpaces() : ''"
                  />

                  
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-btn color="primary" @click="e6 = 3"> Continue </v-btn>
        <v-btn text class="ml-2" @click="e6 = 1"> Cancel </v-btn>
      </v-stepper-content>

      <v-stepper-step @click="e6 = 3" :complete="e6 > 3" step="3">
        Slides de la pagina
      </v-stepper-step>

      <v-stepper-content step="3">
        <v-container>
          <v-row class="my-5">
            <v-col cols="12" sm="6" md="3" v-for="(item, i) in slides" :key="i">
              <v-card flat class="mb-6" style="height: 360px">
                <div class="d-flex justify-center">
                  <label :for="item.ICON" class="">
                    <img
                      :src="formData[item.ICON]"
                      alt=""
                      srcset=""
                      style="width: 200px"
                    />
                    <input
                      type="file"
                      name=""
                      :id="item.ICON"
                      style="display: none"
                      accept="image/*"
                      @change="(e) => preview(e, item.ICON)"
                    />
                  </label>
                </div>
                <p class="text-center mt-n5 mb-n5">1920 x 600</p>
                <v-card-title class="d-flex justify-center mt-5" primary-title>
                  <v-text-field
                    outlined
                    type="text"
                    v-model="formData[item.TITULO]"
                  />
                  <v-text-field
                    outlined
                    type="text"
                    v-model="formData[item.SUBTITULO]"
                  />
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <v-btn color="primary" @click="e6 = 4"> Continue </v-btn>
        <v-btn text class="ml-2" @click="e6 = 2"> Cancel </v-btn>
      </v-stepper-content>

      <v-stepper-step @click="e6 = 4" :complete="e6 > 4" step="4">
        Area de peticiones
      </v-stepper-step>

      <v-stepper-content step="4">
        <v-container>
          <v-row class="my-5">
            <v-col
              cols="12"
              sm="6"
              md="3"
              v-for="(item, i) in peticion"
              :key="i"
            >
              <v-card flat class="" style="height: 460px">
                <label for="" class="text-center">{{ item.label }}</label>
                <div class="d-flex justify-center">
                  <label :for="item.ICON" class="">
                    <v-img
                      :src="formData[item.ICON]"
                      alt=""
                      srcset=""
                      style="width: 200px"
                    />
                    <input
                      type="file"
                      name=""
                      :id="item.ICON"
                      style="display: none"
                      accept="image/*"
                      @change="(e) => preview(e, item.ICON)"
                    />
                  </label>
                </div>
                <p class="text-center mt-1 mb-5">800 x 950</p>
                <v-card-title class="d-flex justify-center mt-n5" primary-title>
                  <v-text-field
                    type="text"
                    outlined
                    v-model="formData[item.TITULO]"
                  />
                  <v-text-field
                    v-if="item.SUBTITULO"
                    type="text"
                    outlined
                    v-model="formData[item.SUBTITULO]"
                  />
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <v-btn color="primary" @click="e6 = 5"> Continue </v-btn>
        <v-btn text class="ml-2" @click="e6 = 3"> Cancel </v-btn>
      </v-stepper-content>

      <v-stepper-step @click="e6 = 5" :complete="e6 > 5" step="5">
        Area de Video
      </v-stepper-step>

      <v-stepper-content step="5">
        <v-container>
          <v-row class="my-5">
            <v-col cols="12" sm="6" md="3">
              <v-card flat>
                <input
                  type="file"
                  name=""
                  id="Video"
                  style=""
                  accept="video/*"
                  @change="(e) => preview(e, 'Video')"
                />
                <video
                  v-if="formData.Video != ''"
                  height="200px"
                  :src="formData.Video"
                  autoplay
                  controls
                ></video>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <v-btn color="primary" @click="e6 = 6"> Continue </v-btn>
        <v-btn text class="ml-2" @click="e6 = 4"> Cancel </v-btn>
      </v-stepper-content>

      <v-stepper-step @click="e6 = 6" :complete="e6 > 6" step="6">
        Colores
      </v-stepper-step>

      <v-stepper-content step="6">
        <v-container>
          <v-row class="my-5">
            <v-col cols="12" sm="6" md="4">
              Color Primario

              <v-color-picker
                mode="rgba"
                v-model="formData.PrimaryColor"
                elevation="2"
              ></v-color-picker>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              Color Secundario
              <v-color-picker
                mode="rgba"
                v-model="formData.SecondaryColor"
                elevation="2"
              ></v-color-picker>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              Color del Footer
              <v-color-picker
                mode="rgba"
                v-model="formData.FooterColor"
                elevation="2"
              ></v-color-picker>
            </v-col>
          </v-row>
        </v-container>

        <v-btn
          color="primary"
          :disabled="disableSubmit"
          @click="
            $store.dispatch('create_iglesia', formData).then((res) => {
              $store.state.iglesia = res;
              $router.push({ name: 'Pago' });
            })
          "
        >
          Finish
        </v-btn>
        <!-- <v-btn
          color="primary"
         
          to="/crear/pago"
        >
          Finish
        </v-btn> -->
        <v-btn text class="ml-2" @click="e6 = 5"> Cancel </v-btn>
      </v-stepper-content>
    </v-stepper>

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Advertencia
        </v-card-title>

        <v-card-text class="mt-5 text-subtitile">
          Nombre de iglesia existente. Pruebe con otro Nombre
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Ok </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import AboutThree from "../components/about/AboutThree.vue";
import PortfolioThree from "../components/portfolio/PortfolioThree.vue";
let def = require("../assets/images/galery.png");
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    AboutThree,
    PortfolioThree,
  },
  data() {
    return {
      show1: false,
      dialog: false,
      readyToSubmit: "",
      disableSubmit: true,
      defaultImage: "",
      defaultImage2: "",
      defaultImage3: "",
      e6: 1,
      show: false,
      formData: {
        admin_nombre: "",
        admin_email: "",
        admin_password: "",
        iglesia_nombre: "",
        iglesia_email: "",
        iglesia_tel: "",
        iglesia_lema: "",
        iglesia_sublema: "",
        iglesia_facebook: "",
        iglesia_logo: "",
        SLIDE_1_ICON: "",
        SLIDE_1_TITULO: "Titulo",
        SLIDE_1_SUBTITULO: "SubTitulo",
        SLIDE_2_ICON: "",
        SLIDE_2_TITULO: "Titulo",
        SLIDE_2_SUBTITULO: "SubTitulo",
        SLIDE_3_ICON: "",
        SLIDE_3_TITULO: "Titulo",
        SLIDE_3_SUBTITULO: "SubTitulo",
        Peticion_Foto: "",
        Peticion_Titulo: "Titulo",
        Peticion_SubTitulo: "Subtitulo",
        Video: "",
        prefijo: "",
        PrimaryColor: "#AF5BE0FF",
        SecondaryColor: "#AF5BE0FF",
        FooterColor: "#AF5BE0FF",
        Foto_Conocenos1: "Titulo",
        Titulo_Conocenos1: "",
        Donaciones: true,
      },
      iglesiaInfo: [
        {
          type: "text",
          rules: "required",
          model: "prefijo",
          label: "Prefijo",
          function: true,
          prefix: true,
        },
        {
          type: "text",
          rules: "required",
          model: "iglesia_nombre",
          label: "Nombre Iglesia",
        },
        {
          type: "text",
          rules: "required|email",
          model: "iglesia_email",
          label: "Email Iglesia",
        },
        {
          type: "text",
          rules: "required",
          model: "iglesia_lema",
          label: "Lema",
        },
        {
          type: "text",
          rules: "required",
          model: "iglesia_sublema",
          label: "Sub lema",
        },
        {
          type: "text",
          rules: {
            regex: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
          },
          model: "iglesia_tel",
          label: "Telefono",
        },
        {
          type: "text",
          rules: "",
          model: "iglesia_facebook",
          label: "Facebook",
        },
      ],
      slides: [
        // {
        //   ICON: "SLIDE_1_ICON",
        //   TITULO: "SLIDE_1_TITULO",
        //   SUBTITULO: "SLIDE_1_SUBTITULO",
        // },
        {
          ICON: "SLIDE_2_ICON",
          TITULO: "SLIDE_2_TITULO",
          SUBTITULO: "SLIDE_2_SUBTITULO",
        },
        {
          ICON: "SLIDE_3_ICON",
          TITULO: "SLIDE_3_TITULO",
          SUBTITULO: "SLIDE_3_SUBTITULO",
        },
      ],
      peticion: [
        {
          label: "Peticiones",
          ICON: "Peticion_Foto",
          TITULO: "Peticion_Titulo",
          SUBTITULO: "Peticion_SubTitulo",
        },
        {
          label: "Conocenos",
          ICON: "Foto_Conocenos1",
          TITULO: "Titulo_Conocenos1",
          SUBTITULO: null,
        },
      ],
    };
  },
  watch: {
    readyToSubmit(newval, oldval) {
      console.log(this.formData.PrimaryColor);
      if (newval == "Nombre Existente") {
        this.dialog = true;
        this.readyToSubmit = "";
        this.disableSubmit = true;
        console.log("Nombre Existente");
      } else if (newval == "Ok") {
        this.disableSubmit = false;
      }
    },
  },
  created() {
    this.formData.iglesia_logo = require("../assets/images/galery.png");
    this.formData.SLIDE_1_ICON = require("../assets/images/galery.png");
    this.formData.SLIDE_2_ICON = require("../assets/images/galery.png");
    this.formData.SLIDE_3_ICON = require("../assets/images/galery.png");

    this.formData.Peticion_Foto = require("../assets/images/galery.png");
    this.formData.Foto_Conocenos1 = require("../assets/images/galery.png");
  },
  methods: {
    preview(e, id) {
      console.log(id);
      if (id == "Video") {
        this.video(e);
      }
      if (e.target.files.length > 0) {
        let _this = this;
        var reader = new FileReader();
        reader.onload = function () {
          var output = document.getElementById(id);
          output.src = reader.result;
          console.log(reader.result);
          _this.formData[id] = output.src;
        };
        reader.readAsDataURL(e.target.files[0]);
      } else {
        return;
      }
    },
    slideicon(id) {
      console.log(id);
    },
    video(e) {
      let file = e.target.files[0];
      let blobURL = URL.createObjectURL(file);

      //document.querySelector("video").src = blobURL;
      this.formData.Video = blobURL;
      console.log(this.formData.Video);
    },
    Openweb(Nombre_Id) {
      window.open(
        `http://74.213.107.126:93/Church/resources/dist/#/${Nombre_Id}`,
        "_self"
      );
    },
    RemoveSpaces() {
      this.formData.prefijo = this.formData.prefijo.replace(/\s/g, "");
    },
  },
};
</script>

<style lang="scss">
.w-75 {
  width: 75%;
  @media (max-width: 767.98px) {
    width: 100%;
  }
}

.info-iglesia {
  display: flex;
  align-items: center;
  img {
    position: relative;
    width: 350px;
    margin-right: 10px;
  }
  @media (max-width: 767.98px) {
    flex-direction: column;

    img {
      width: 100%;
      margin-bottom: 10px;
    }
    .info-content {
      width: 100%;
    }
  }
}
</style>
